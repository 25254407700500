<template>
<temp-default>
    <div class="bl_wrapper bl_wrapper__2column">
        <div class="bl_form ly_form">
            <app-section class="uq_ondokoukankouritsu">
                <h3 slot="h3">
                    計算条件の入力
                </h3>
                <app-box
                    :available="true"
                    label="温度交換効率" 
                    class="flex_column app_box__row"
                >
                    <app-text
                        label=""
                        unit="%"
                        v-model="etaT"
                        class="number"
                        subscript="(整数)"
                        :step="1"
                        @input="calc"
                    />
                </app-box>
                <app-box
                    :available="true"
                    label="第一種換気設備の場合における有効換気量率"
                    class="flex_column app_box__row"
                >
                    <app-text
                        label=""
                        unit=""
                        v-model="rNsa"
                        class="number"
                        :step="0.01"
                        @input="calc"
                    />
                </app-box>

            <!-- 形状 -->
                <h3>
                    <span>熱交換型換気設備の熱交換素子の形状</span>
                </h3>
                <app-box
                    :available="true"
                    label="" 
                    class="flex_column"
                >
                    <app-radio
                        v-model="shape"
                        value="1"
                        name="shape"
                        @change="calc"
                    >
                        直交流型
                    </app-radio>
                    <app-radio
                        v-model="shape"
                        value="2"
                        name="shape"
                        @input="calc"
                        :disabled="true"
                    >
                        向流－直向流複合型
                    </app-radio>
                <information-box title="現時点で「向流－直向流複合型」の計算はできません。">
                    <p>「向流－直向流複合型」の補正係数の計算を行う場合は、建築研究所のホームページに公表されている評価方法（第三章「暖冷房負荷と外皮性能」第一節「全般」）に則って計算してください。</p>
                </information-box>
                </app-box>
                

            <!-- 風量 -->
                <h3>
                    <span>風量</span>
                </h3>
                <app-box
                    :available="true"
                    label="定格条件における給気風量 Vrtd,SA" 
                    class="flex_column app_box__row"
                >
                    <app-text
                        label=""
                        unit="m3/h"
                        v-model="vRtdSa"
                        class="number"
                        @input="calc"
                    />
                </app-box>
                <app-box
                    :available="true"
                    label="定格条件における還気風量 Vrtd,RA"
                    class="flex_column app_box__row"
                >
                    <app-text
                        label=""
                        unit="m3/h"
                        v-model="vRtdRa"
                        class="number"
                        @input="calc"
                    />
                </app-box>
                <app-box
                    :available="true"
                    label="当該住戸における設計給気風量 Vd,SA"
                    class="flex_column app_box__row"
                >
                    <app-text
                        label=""
                        unit="m3/h"
                        v-model="vDSa"
                        class="number"
                        @input="calc"
                    />
                </app-box>
                <app-box
                    :available="true"
                    label="当該住戸における設計還気風量 Vd,RA"
                    class="flex_column app_box__row"
                >
                    <app-text
                        label=""
                        unit="m3/h"
                        v-model="vDRa"
                        class="number"
                        @input="calc"
                    />
                </app-box>
                <h3 v-if="shape == 2">
                    <span>複合型熱交換素子</span>
                </h3>
                <app-box
                    v-if="shape == 2"
                    :available="true"
                    label="向流部の幅" 
                    class="flex_column app_box__row"
                >
                    <app-text
                        label=""
                        unit="[m]"
                        v-model="diversionPartWidth"
                        class="number"
                        @input="calc"
                    />
                </app-box>
                <app-box
                    v-if="shape == 2"
                    :available="true"
                    label="向流部の長さ"
                    class="flex_column app_box__row"
                >
                    <app-text
                        label=""
                        unit="[m]"
                        v-model="diversionPartLength"
                        class="number"
                        @input="calc"
                    />
                </app-box>
                <app-box
                    v-if="shape == 2"
                    :available="true"
                    label="向流部と直交流部の接続角度"
                    class="flex_column app_box__row"
                >
                    <app-text
                        label=""
                        unit="[°]"
                        v-model="diversionPartAngle"
                        class="number"
                        @input="calc"
                    />
                </app-box>
            </app-section>
        </div>
        
        <div class="bl_result">
            <app-section>
                <h3 slot="h3">計算結果</h3>
                <div
                    class="bl_resultMenu_inner ly_resultMenu_inner bl_resultMenu_inner__summary">
                    <div class="bl_resultMenu_inner_result ly_resultMenu_inner_result">
                        <!-- TODO 計算結果の数値を受け取りたいです -->
                        <div class="bl_resultMenu_inner_result_box">
                            <span class="bl_resultMenu_inner_result_box_title">
                                給気と排気の比率による<br />温度交換効率の補正係数
                            </span>
                            <span class="bl_resultMenu_inner_result_box_value">{{cBal | toFixed(2)}}</span>
                        </div>
                        <div class="bl_resultMenu_inner_result_box">
                            <span class="bl_resultMenu_inner_result_box_title">
                            排気過多時における住宅外皮経由の<br />漏気による温度交換効率の補正係数
                            </span>
                            <span class="bl_resultMenu_inner_result_box_value">{{cLeak | toFixed(2)}}</span>
                        </div>
                    </div>
                </div>
                <div
                    class="bl_resultMenu_inner ly_resultMenu_inner bl_resultMenu_inner__process">
                    <h4 class="result_title">計算過程
                        <small>クリックで数式を表示します</small>
                    </h4>
                    <div>
                        <label for="formula_2_C_bal" class="bl_calcProcess_list">
                            <input id="formula_2_C_bal" type="checkbox" class="bl_calcProcess_list_checkbox">
                            <div class="bl_calcProcess_list_data">
                                <span class="bl_calcProcess_list_data_header">補正係数 Cbal</span>
                                <span class="bl_calcProcess_list_data_value">= {{cBal | toFixed(2)}}</span>
                            </div>
                            <dd class="bl_calcProcess_list_formula">
                                <a href="/img/2_C_bal.png" target="_blank">
                                    <img src="/img/2_C_bal.png" />
                                </a>
                            </dd>
                        </label>
                        <label for="formula_3_eta_t_d" class="bl_calcProcess_list">
                            <input id="formula_3_eta_t_d" type="checkbox" class="bl_calcProcess_list_checkbox">
                            <div class="bl_calcProcess_list_data">
                                <span class="bl_calcProcess_list_data_header">当該住戸における設計風量比での温度交換効率 ηt,d</span>
                                <span class="bl_calcProcess_list_data_value">= {{etaTD | toFixed(2)}}</span>
                            </div>
                            <dd class="bl_calcProcess_list_formula">
                                <a href="/img/3_eta_t_d.png" target="_blank">
                                    <img src="/img/3_eta_t_d.png" />
                                </a>
                            </dd>
                        </label>
                        <label for="formula_4a_eta_d" class="bl_calcProcess_list">
                            <input id="formula_4a_eta_d" type="checkbox" class="bl_calcProcess_list_checkbox">
                            <div class="bl_calcProcess_list_data">
                                <span class="bl_calcProcess_list_data_header">当該住戸における設計風量比での熱通過有効度 ηd </span>
                                <span class="bl_calcProcess_list_data_value">= {{etaD | toFixed(2)}}</span>
                            </div>
                            <dd class="bl_calcProcess_list_formula">
                                <a href="/img/4a_eta_d.png" target="_blank">
                                    <img src="/img/4a_eta_d.png" />
                                </a>
                            </dd>
                        </label>
                        <label for="formula_5_R_vnt_d_dash" class="bl_calcProcess_list">
                            <input id="formula_5_R_vnt_d_dash" type="checkbox" class="bl_calcProcess_list_checkbox">
                            <div class="bl_calcProcess_list_data">
                                <span class="bl_calcProcess_list_data_header">当該住戸における補正設計風量比 R'vnt,d </span>
                                <span class="bl_calcProcess_list_data_value">= {{rDashVntD | toFixed(2)}}</span>
                            </div>
                            <dd class="bl_calcProcess_list_formula">
                                <a href="/img/5_R_vnt_d_dash.png" target="_blank">
                                    <img src="/img/5_R_vnt_d_dash.png" />
                                </a>
                            </dd>
                        </label>
                        <label for="formula_6_R_vnt_d" class="bl_calcProcess_list">
                            <input id="formula_6_R_vnt_d" type="checkbox" class="bl_calcProcess_list_checkbox">
                            <div class="bl_calcProcess_list_data">
                                <span class="bl_calcProcess_list_data_header">当該住戸における設計風量比 Rvnt,d </span>
                                <span class="bl_calcProcess_list_data_value">= {{rVntD | toFixed(2)}}</span>
                            </div>
                            <dd class="bl_calcProcess_list_formula">
                                <a href="/img/6_R_vnt_d.png" target="_blank">
                                    <img src="/img/6_R_vnt_d.png" />
                                </a>
                            </dd>
                        </label>
                        <label for="formula_7_N_d" class="bl_calcProcess_list">
                            <input id="formula_7_N_d" type="checkbox" class="bl_calcProcess_list_checkbox">
                            <div class="bl_calcProcess_list_data">
                                <span class="bl_calcProcess_list_data_header">当該住戸における設計風量比での伝熱単位数 Nd </span>
                                <span class="bl_calcProcess_list_data_value">= {{nD | toFixed(2)}}</span>
                            </div>
                            <dd class="bl_calcProcess_list_formula">
                                <a href="/img/7_N_d.png" target="_blank">
                                    <img src="/img/7_N_d.png" />
                                </a>
                            </dd>
                        </label>
                        <label for="formula_8_V_rtd_min" class="bl_calcProcess_list">
                            <input id="formula_8_V_rtd_min" type="checkbox" class="bl_calcProcess_list_checkbox">
                            <div class="bl_calcProcess_list_data">
                                <span class="bl_calcProcess_list_data_header">定格条件における最小風量 Vrtd,min </span>
                                <span class="bl_calcProcess_list_data_value">= {{vRtdMin | toFixed(2)}}</span>
                            </div>
                            <dd class="bl_calcProcess_list_formula">
                                <a href="/img/8_V_rtd_min.png" target="_blank">
                                    <img src="/img/8_V_rtd_min.png" />
                                </a>
                            </dd>
                        </label>
                        <label for="formula_9_V_d_min" class="bl_calcProcess_list">
                            <input id="formula_9_V_d_min" type="checkbox" class="bl_calcProcess_list_checkbox">
                            <div class="bl_calcProcess_list_data">
                                <span class="bl_calcProcess_list_data_header">当該住戸における設計最小風量 Vd,min</span>
                                <span class="bl_calcProcess_list_data_value">= {{vDMin | toFixed(2)}}</span>
                            </div>
                            <dd class="bl_calcProcess_list_formula">
                                <a href="/img/9_V_d_min.png" target="_blank">
                                    <img src="/img/9_V_d_min.png" />
                                </a>
                            </dd>
                        </label>
                        <label for="formula_10a_eta_t" class="bl_calcProcess_list">
                            <input id="formula_10a_eta_t" type="checkbox" class="bl_calcProcess_list_checkbox">
                            <div class="bl_calcProcess_list_data">
                                <span class="bl_calcProcess_list_data_header">定格条件における風量比での伝熱単位数 Nrtd</span>
                                <span class="bl_calcProcess_list_data_value">= {{nRtd | toFixed(2)}}</span>
                            </div>
                            <dd class="bl_calcProcess_list_formula">
                                <a href="/img/10a_eta_t.png" target="_blank">
                                    <img src="/img/10a_eta_t.png" />
                                </a>
                            </dd>
                        </label>
                        <label for="formula_11_eta" class="bl_calcProcess_list">
                            <input id="formula_11_eta" type="checkbox" class="bl_calcProcess_list_checkbox">
                            <div class="bl_calcProcess_list_data">
                                <span class="bl_calcProcess_list_data_header">熱交換型換気設備の熱通過有効度 η</span>
                                <span class="bl_calcProcess_list_data_value">= {{eta | toFixed(2)}}</span>
                            </div>
                            <dd class="bl_calcProcess_list_formula">
                                <a href="/img/11_eta.png" target="_blank">
                                    <img src="/img/11_eta.png" />
                                </a>
                            </dd>
                        </label>
                        <label for="formula_12_R_vnt_rtd_dash" class="bl_calcProcess_list">
                            <input id="formula_12_R_vnt_rtd_dash" type="checkbox" class="bl_calcProcess_list_checkbox">
                            <div class="bl_calcProcess_list_data">
                                <span class="bl_calcProcess_list_data_header">定格条件における補正風量比 R'vnt,rtd</span>
                                <span class="bl_calcProcess_list_data_value">= {{rDashVntRtd | toFixed(2)}}</span>
                            </div>
                            <dd class="bl_calcProcess_list_formula">
                                <a href="/img/12_R_vnt_rtd_dash.png" target="_blank">
                                    <img src="/img/12_R_vnt_rtd_dash.png" />
                                </a>
                            </dd>
                        </label>
                        <label for="formula_13_R_vnt_rtd" class="bl_calcProcess_list">
                            <input id="formula_13_R_vnt_rtd" type="checkbox" class="bl_calcProcess_list_checkbox">
                            <div class="bl_calcProcess_list_data">
                                <span class="bl_calcProcess_list_data_header">定格条件における風量比 Rvnt,rtd</span>
                                <span class="bl_calcProcess_list_data_value">= {{rVntRtd | toFixed(2)}}</span>
                            </div>
                            <dd class="bl_calcProcess_list_formula">
                                <a href="/img/13_R_vnt_rtd.png" target="_blank">
                                    <img src="/img/13_R_vnt_rtd.png" />
                                </a>
                            </dd>
                        </label>
                        <dl class="bl_calcProcess_list">
                            <dt class="bl_calcProcess_list_data">
                                <span class="bl_calcProcess_list_data_header">熱交換型換気設備の温度交換効率 ηt </span>
                                <span class="bl_calcProcess_list_data_value">= {{showEtaT | toFixed(2)}}</span>
                            </dt>
                            <dd class="bl_calcProcess_list_formula"></dd>
                        </dl>
                        <dl class="bl_calcProcess_list">
                            <dt class="bl_calcProcess_list_data">
                                <span class="bl_calcProcess_list_data_header">定格条件における給気風量 Vrtd,SA</span>
                                <span class="bl_calcProcess_list_data_value">= {{vRtdSa | toFixed(2)}}</span>
                            </dt>
                            <dd class="bl_calcProcess_list_formula"></dd>
                        </dl>
                        <dl class="bl_calcProcess_list">
                            <dt class="bl_calcProcess_list_data">
                                <span class="bl_calcProcess_list_data_header">定格条件における還気風量 Vrtd,RA</span>
                                <span class="bl_calcProcess_list_data_value">= {{vRtdRa | toFixed(2)}}</span>
                            </dt>
                            <dd class="bl_calcProcess_list_formula"></dd>
                        </dl>
                        <dl class="bl_calcProcess_list">
                            <dt class="bl_calcProcess_list_data">
                                <span class="bl_calcProcess_list_data_header">当該住戸における設計給気風量 Vd,SA </span>
                                <span class="bl_calcProcess_list_data_value">= {{vDSa | toFixed(2)}}</span>
                            </dt>
                            <dd class="bl_calcProcess_list_formula"></dd>
                        </dl>
                        <dl class="bl_calcProcess_list">
                            <dt class="bl_calcProcess_list_data">
                                <span class="bl_calcProcess_list_data_header">当該住戸における設計還気風量 Vd,RA</span>
                                <span class="bl_calcProcess_list_data_value">= {{vDRa | toFixed(2)}}</span>
                            </dt>
                            <dd class="bl_calcProcess_list_formula"></dd>
                        </dl>
                    </div>
                </div>
            </app-section>
        </div>
        
    </div>
    <p class="bl_msg__center">届出等に使用する場合は、この画面を印刷します。</p>
</temp-default>
</template>

<script>
import TempDefault from '@/components/templates/TempDefault.vue'
import { mapComputedProperties } from '@/util'
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'AppForm',
    components: {
        TempDefault
    },
    computed:{
        ...mapGetters('calc', {
            data:'calcData',
        }),
        ...mapComputedProperties([
            'cBal',
            'cLeak',
            'etaT',
            'showEtaT',
            'rNsa',
            'shape',
            'vRtdSa',
            'vRtdRa',
            'vDSa',
            'vDRa',
            'etaTD',
            'etaD',
            'rDashVntD',
            'rVntD',
            'nD',
            'vRtdMin',
            'vDMin',
            'nRtd',
            'eta',
            'rDashVntRtd',
            'rVntRtd',
            'diversionPartWidth',
            'diversionPartLength',
            'diversionPartAngle',
        ])
    },
    mounted: function () {
        window.addEventListener('resize', this.handleResize)
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        ...mapActions('calc', [
            'update',
            'calc',
        ]),
        handleResize() {
            // ウインドウサイズがリサイズされたとき、ウインドウサイズを取得
            this.width = window.innerWidth;
            this.height = window.innerHeight;
        },
    },
    filters: {
        toFixed(num, n) {
            if (!num) return
            num = +num
            if (n > 1) {
                return num.toFixed(n)
            } else {
                return num.toFixed()
            }
        }
    }
}
</script>

<style scoped>
.uq_ondokoukankouritsu >>> .app_box {
    grid-template-columns: 20em 1fr;
    -ms-grid-columns: 20em 1fr;
}

.app_box {
    grid-template-columns: 14em 1fr;
    -ms-grid-columns: 14em 1fr;
}
.bl_wrapper__2column{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.bl_wrapper__2column>div{
    width: 49%;
    min-width: 400px;
}

.bl_wrapper__2column >>> .app_section{
    width: 100%;
    min-width: 300px;
    margin: 0 auto;
}
.uq_flex__row{
    display: flex;
    flex-direction: row;
}
.section_wrap h3>span{
   padding-top: 16px; 
   padding-left: 16px;
}
.bl_form >>> .app_box.app_box__row .grid_1{
    width: 100%;
}
.bl_form >>> .app_box.app_box__row .grid_2{
    width: 13em;
}
/* 計算結果 */
.bl_result >>> .app_section{
    padding: 16px;
    background-color: rgba(83, 191, 216, 0.1);
}
.ly_resultMenu_inner{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    padding: 0 8px 8px;
}
.bl_resultMenu_inner__summary{
    width: 100%;
    margin: 0 auto;
}
.bl_resultMenu_inner__summary .result_title{
    width: 100%;
}
.result_title small{
    margin-left: auto;
    font-weight: 500;
}
.bl_resultMenu_inner__summary .bl_resultMenu_inner_result{
    width: 100%;
}
.bl_resultMenu_inner_result_box:first-child{
    border-bottom: 1px solid #ccc;
}
.bl_resultMenu_inner_result_box_title{
    min-width: 16em;
    margin-right: 16px;
    font-size: 12px;
    text-align: left;
}
.bl_resultMenu_inner_result{
    background-color: white;
    border: 2px solid #53BFD8;
    border-radius: 8px;
}
.ly_resultMenu_inner_result{
    display: flex;
    flex-direction: column;
    padding: 8px;
}
.bl_resultMenu_inner_result_box{
    padding: 4px 16px;
}

.bl_resultMenu_inner_result {
    margin: 0 auto 50px;
}
.result_title,
.bl_resultMenu_inner_result_box{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    font-size: 16px;
    font-weight: bolder;
}
.bl_resultMenu_inner_result_box_value{
    margin-left: 8px;
    font-size: 24px;
}
_:-ms-lang(x)::-ms-backdrop, .ly_bottomMenu_inner{
    justify-content: center;
}
_:-ms-lang(x)::-ms-backdrop, .ly_bottomMenu_inner .result_title{
    margin-right: 8px;
}
.bl_calcProcess{
    padding: 8px;
    background-color: white;
    border-radius: 8px;

}
.bl_resultMenu_inner__process{
    width: 100%;
}
.bl_resultMenu_inner__process .result_title{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    text-align: left;
}
.bl_calcProcess_list{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding: 8px 0;
}
.bl_calcProcess_list_checkbox{
    display: none;
}
.bl_calcProcess_list_checkbox:checked~.bl_calcProcess_list_formula{
    display: flex;
}
.bl_calcProcess_list:not(:last-child){
    border-bottom: 1px solid #53BFD8;
}
.bl_calcProcess_list_data{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

label.bl_calcProcess_list .bl_calcProcess_list_data:before{
    content:"";
    display: inline-flex;
    width: 0;
    height: 0;
    margin-right: 4px;
    border-left: 8px solid #41B8D4;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
}
label .bl_calcProcess_list_checkbox:checked~.bl_calcProcess_list_data:before{
    transform: rotate(90deg);
}
.bl_calcProcess_list.bl_calcProcess_list_data:hover{
    cursor: pointer;
    background-color: rgba(83, 191, 216, 0.1);
}

/* 矢印を消す。Chrome、Safari */
.bl_calcProcess_list::-webkit-details-marker {
    font-size: 8px;
    color: #53BFD8;
}
.bl_calcProcess_list_data_header{
    width: calc(85% - 32px);
    font-size: 12px;
    font-weight: bolder;
    box-sizing: border-box;
}
dt.bl_calcProcess_list_data .bl_calcProcess_list_data_header{
    width: calc(85% - 24px);
}
.bl_calcProcess_list_data_value{
    width: 15%;
    min-width: 3em;
    margin-left: 8px;
    font-size: 18px;
    font-weight: bolder;
    white-space: nowrap;
    line-height: 16px;
    box-sizing: border-box;
}
.bl_calcProcess_list_data_value small{
    font-size: 12px;
}
.bl_calcProcess_list_formula{
    display: none;
}
.bl_calcProcess_list_formula a img{
    max-width: calc(850px - 32em);
}
.bl_msg__center{
    width: 100%;
    text-align: center;
    margin: 16px auto;
}

_:-ms-lang(x)::-ms-backdrop, .bl_calcProcess_list{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
_:-ms-lang(x)::-ms-backdrop, .bl_calcProcess_list{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
}
/* レスポンシブ（タブレット縦よりウインドウ幅が狭いもの） */
@media screen and (max-width: 1000px) {
    .ly_resultMenu{
        left: calc(50vw - 50% + 30px);
    }
    .ly_resultMenu_inner{
        flex-wrap: wrap;
    }
    .bl_resultMenu_inner__summary .bl_resultMenu_inner_result{
        width: 100%;
    }
    .bl_resultMenu_inner_result_box{
        flex-direction: column;
    }
    .bl_resultMenu_inner_result_box_title{
        width: 100%;
        text-align: center;
    }
    .bl_calcProcess_list_data{
        min-width: 100%;
    }
    .bl_calcProcess_list_data_header{
        min-width: fit-content;
    }
}
</style>